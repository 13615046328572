<template>
      <a-card title="">
        <perfect-scrollbar style="height:400px">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <ve-bar 
            judge-width
            :legend="{show:true,bottom:10}" 
            :grid="{right: '5%'}"
            :toolbox="toolbox"
            :height="wHeight"
            :title="{
                text:`${title}（${rank_data.title?rank_data.title:'0'}）`,
                subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            }" 
            :data="rank_data"
            :settings="chartSettings" 
            :extend="handleExtend"></ve-bar>
            <LEmpty v-if="first>0 && rank_data.rows.length == 0"/>
        </div>
        </perfect-scrollbar>
    </a-card>
</template>

<script>
export default {
  name: 'LRanking',
  props: {
    start_date: String,
    end_date: String,
    rank_data:Object,
    title:String,
    loading:Boolean,
    line_color:String
  },
  data(){
      this.chartSettings = {
        label: { show: true, position: 'insideTopRight',},
        yAxisType: ['KMB', 'KMB'],
        stack: {'跟进量':["客户跟进","客户转化","线索跟进"]}
      }
      this.markLine = {
          symbol: ['none', 'none'], //去掉箭头
          data: [
          {
              name: '平均线',
              type: 'average'
          }
          ]
      },
      this.toolbox = {
          feature: {
          magicType: {},
          saveAsImage: {pixelRatio:2}
          }
      }
      return{
          first:0,
          handleExtend:{
            series(item){
                if(item.length > 0){
                item[0].type = 'bar'
                item[0].barMaxWidth = 12.5
                let itemValue1 = ''
                item[0].barMinHeight = 15
                item[0].label = Object.assign({}, {
                    show:true,
                    position: 'inside',
                    formatter: (params)=>{
                        itemValue1 = params.value
                        if(params.value==0){  //为0时不显示
                            return 0 
                        }else{
                            return params.value
                        }
                    }
                })
                item[0].itemStyle = Object.assign({}, {
                    normal:{
                        barBorderRadius:1
                    }
                })
                // if(itemValue1==0){
                //     item[0].barMinHeight = 0
                // }else{
                //     item[0].barMinHeight = 25
                // }
                item[1].type = 'bar'
                item[1].barMaxWidth = 12.5
                item[1].barMinHeight = 15
                item[1].label = Object.assign({}, {
                    show:true,
                    position: 'inside',
                    formatter: (params)=>{
                        if(params.value==0){  //为0时不显示
                            // item[1].barMinHeight = 0
                            return 0 
                        }else{
                            // item[1].barMinHeight = 25
                            return params.value
                        }
                    }
                })
                item[1].itemStyle = Object.assign({}, {
                    normal:{
                        barBorderRadius: 1,
                    }
                })
                item[2].type = 'bar'
                item[2].barMaxWidth = 12.5
                item[2].barMinHeight = 15
                item[2].label = Object.assign({}, {
                    show:true,
                    position: 'inside',
                    formatter: (params)=>{
                        if(params.value==0){  //为0时不显示
                            // item[2].barMinHeight = 0
                            return 0 
                        }else{
                            // item[2].barMinHeight = 25
                            return params.value
                        }
                    }
                })
                item[2].itemStyle = Object.assign({}, {
                    normal:{
                        barBorderRadius: 1,
                    }
                })
                // item[3].type = 'bar'
                // item[3].barMaxWidth = 15
                // item[3].barMinHeight = 15
                // item[3].label = Object.assign({}, {
                //     show:true,
                //     position: 'inside',
                //     formatter: (params)=>{
                //         if(params.value==0){  //为0时不显示
                //             // item[2].barMinHeight = 0
                //             return 0 
                //         }else{
                //             // item[2].barMinHeight = 25
                //             return params.value
                //         }
                //     }
                // })
                // item[3].itemStyle = Object.assign({}, {
                //     normal:{
                //         barBorderRadius: 1.5,
                //     }
                // })
                }
                
                // console.log(item)
                return item
            },
            xAxis:{
                splitLine: {
                    lineStyle: {
                        type: 'dotted'
                    }
                },
                max:'dataMax'
            },
            yAxis:{
                inverse: true,  
                splitLine:{show: false},
                axisLabel: {
                    color: '#333',
                    rich: {
                        b: {
                            color: '#f4bc2f',
                            align: 'center',
                        }
                    },
                    formatter: function(params,index) {
                        return [
                                `${params} {b| ${index + 1}}`
                            ].join('\n')
                    }
                }
            },

          }
      }
  },
  computed: {
        wHeight() {
            let height = 400
            if(this.rank_data.rows && this.rank_data.rows.length > 10){
                height = 400 + ( this.rank_data.rows.length - 10 ) * 20
            }
            return `${height}px`
        }
  },
  methods:{
      handleFormatter(params){
          if(params.value==0){  //为0时不显示
                return '' 
            }else{
                return params.value
            }
      }
  },
  watch: {
    loading(newValue, oldValue) {
        this.first++;
    }
  },
}
</script>
