<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>{{followName == '1'?'市场数据分析':'招生客户分析'}}</a-breadcrumb-item>
            <a-breadcrumb-item>{{followName == '1'?'线索跟进分析':'客户跟进分析'}}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                <a-form layout='inline'>
                    <a-form-item>
                    <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                        日
                        </a-radio-button>
                        <a-radio-button value="week">
                        周
                        </a-radio-button>
                        <a-radio-button value="month">
                        月
                        </a-radio-button>
                        <a-radio-button value="year">
                        年
                        </a-radio-button>
                    </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>时间筛选</span>
                        </template>
                        <a-range-picker
                        :ranges="rangesData"
                        :value='rangesValue'
                        :disabled-date="disabledDate" :allowClear="false"
                        :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                        @change="(date, dateString)=>onChange(date, dateString,1)" />
                    </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    <a-form-item>
                        <a-popover placement="bottom" trigger="click" v-model="visible">
                        <template slot="content">
                            <div class="more-search">
                            <a-form layout='inline'>
                                <a-form-item class="block-line" label="来源渠道">
                                    <a-tree-select
                                        v-model="paramsData.channel_id"
                                        :tree-data="parentChannels"
                                        tree-checkable
                                        allowClear
                                        treeNodeFilterProp="title"
                                        :maxTagCount='1'
                                        :maxTagTextLength='5'
                                        :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                        :show-checked-strategy="SHOW_ALL"
                                        placeholder="请选择来源渠道"
                                    />
                                </a-form-item>

                                <a-form-item class="block-line" label="所属校区">
                                  <a-select v-model="paramsData.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                                      <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                  </a-select>
                                </a-form-item>

                                <!-- <a-form-item class="block-line" label="添加人">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.created_by' allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item> -->

                                <a-form-item class="block-line" label="跟进事件">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.event_id' allowClear showSearch placeholder="请选择跟进事件" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of eventList" :key="index" :value="d['event_id']">{{ d['event_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item class="block-line" label="跟进人">
                                    <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='paramsData.follow_by' allowClear showSearch @search="handleSearch" placeholder="请选择跟进人" :filterOption="filterOption">
                                    <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                </a-form-item>

                            </a-form>
                            <div class="more-search__buttons">
                                <a-button type="dashed" @click="closeSearch">取消</a-button>
                                <a-button @click="reset">重置</a-button>
                                <a-button type="primary" @click="toScreen">搜索</a-button>
                            </div>
                            </div>
                        </template>
                        <a-button>更多<a-icon type="down" /></a-button>
                        </a-popover>
                    </a-form-item>
                </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                
                <a-row class="mb16" :gutter="16">

                    <a-col :lg="24" :xl="24">
                      <leadsFollowCount v-if="followName == '1'" :loading='loading' title="线索跟进统计"  :leads_data ='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                      <followCount v-else :loading='loading' title="客户跟进统计"  :leads_data ='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <heatmap :loading="loadingFH" :item="heatmap_data" title="客户跟进时间热点图" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>


                    <a-col :lg="24" :xl="24">
                      <a-card size="small" :title="followName==1?'员工线索跟进统计':'员工跟进统计'">
                        <a-button slot="extra" @click="toExport" size="small" icon="export" :loading="exportLoading">导出</a-button>
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id"
                        :columns="col_employee_follow_columns" :dataSource="col_employee_follow" :scroll="{ x: RelativeWidth, y:500}">
                        </a-table>
                      </a-card>
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <a-card size="small" :title="followName==1?'校区线索跟进统计':'校区跟进统计'">
                        <!-- <a-button slot="extra" @click="toExport" size="small" icon="export" :loading="exportLoading">导出</a-button> -->
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="studio_id"
                        :columns="col_studio_follow_columns" :dataSource="col_studio_follow" :scroll="{ x: RelativeWidth, y:500}">
                        </a-table>
                      </a-card>
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <a-card size="small" :title="followName==1?'渠道线索跟进统计':'渠道跟进统计'">
                        <!-- <a-button slot="extra" @click="toExport" size="small" icon="export" :loading="exportLoading">导出</a-button> -->
                        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="channel_id"
                        :columns="col_channel_follow_columns" :dataSource="col_channel_follow" :scroll="{ x: RelativeWidth, y:500}">
                        </a-table>
                      </a-card>
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking v-if="followName == '1'" title="校区线索跟进统计" :rank_data="studio_follow_data" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                      <studioFollowCustomer v-else title="校区跟进统计" :rank_data="studio_follow_data" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking v-if="followName == '1'" title="顾问线索跟进统计" :rank_data="course_consultant_follow_data" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />  
                      <studioFollowCustomer v-else title="顾问跟进统计" :rank_data="course_consultant_follow_data" :loading="loading" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />  
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="渠道大类客户跟进统计" :rank_data="follow_channel_data" :loading="loadingFC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />  
                    </a-col>

                    <a-col :lg="24" :xl="12">
                      <LRanking title="细分渠道客户跟进统计" :rank_data="follow_sub_channel_data" :loading="loadingFC" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />  
                    </a-col>

                    <!-- <a-col :lg="24" :xl="24">
                    <contactType :loading='loading'  :contact_type_data ='contact_type_data'/>
                    </a-col> -->
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import heatmap from './heatmap'
import followCount from './follow_count'
import leadsFollowCount from './leads_follow_count'
import studioFollowCustomer from './studio_follow_customer'
import contactType from './contact_type'
import statistics from '@/views/bi/ranking/money/statistics'


export default {
  name: 'follow',
  components:{
    heatmap,
    followCount,
    contactType,
    statistics,
    leadsFollowCount,
    studioFollowCustomer
  },
  data() {
    return {
      loading:false,
      visible:false,
      loadingFC:false,
      loadingFH:false,
      exportLoading:false,
      col_employee_follow:[],
      col_employee_follow_columns:[],
      col_studio_follow:[],
      col_studio_follow_columns:[],
      col_channel_follow:[],
      col_channel_follow_columns:[],
      leads_data:{},
      heatmap_data:{},
      contact_type_data:{},
      studio_follow_data:{},
      follow_channel_data:{},
      follow_sub_channel_data:{},
      course_consultant_follow_data:{},
      counts:[],
      studios:[],
      channelsId:[],
      parentChannels: [],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:undefined,
        log_type:'',
        filter_type:'day'
      },
      followName:'',
      staffList:[],
      eventList:[]
    }
  },
  mixins: [ranges],
  created() {
    this.followName = this.$route.meta.name
    this.paramsData.log_type = this.followName == '1'?'leads':'customer'
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
    this.getStaff()
    this.getEventList()
    this.getSourceChannel()
    
  },
  computed: {
      RelativeWidth() {
          let width = 850
          if(this.filedLength >10){
          width = 900 + ( this.filedLength - 10 ) * 130
          }
          return width
      }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      this.getCount(obj)
      this.getHeatmap(obj)
      this.getFollowChannel(obj)
      let res = await this.$store.dispatch('analysisCrmFollowAction', obj)
      this.leads_data = res.data
      let res_rank = await this.$store.dispatch('analysisCrmFollowRankAction', obj);
      this.studio_follow_data=res_rank.data.studio_follow;
      this.course_consultant_follow_data=res_rank.data.course_consultant_follow;

      
      let single_col_date = 
      [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '员工名称', width:'120px', dataIndex: 'employee_name',  key: 'employee_name', fixed: 'left',ellipsis:true},
          { title: '合计', width:'60px', dataIndex: 'employee_summary_total', key: 'employee_summary_total',fixed: 'left',align:'right'},
      ]
      if(res_rank.data.employee_follow_table){
          res_rank.data.employee_follow_table.col_date.map(item=>{
              let obj = { title: item, dataIndex: item, key: item,align:'right',width:'60px'}
              single_col_date.push(obj)
          })
      }

      this.col_employee_follow_columns=single_col_date;
      this.col_employee_follow = res_rank.data.employee_follow_table.rows;


      let channel_col_date = 
      [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '渠道名称', width:'120px', dataIndex: 'channel_name',  key: 'channel_name', fixed: 'left',ellipsis:true},
          { title: '合计', width:'60px', dataIndex: 'channel_summary_total', key: 'channel_summary_total',fixed: 'left',align:'right'},
      ]
      if(res_rank.data.channel_follow_table){
          res_rank.data.employee_follow_table.col_date.map(item=>{
              let obj = { title: item, dataIndex: item, key: item,align:'right',width:'60px'}
              channel_col_date.push(obj)
          })
      }

      this.col_channel_follow = res_rank.data.channel_follow_table.rows;
      this.col_channel_follow_columns = channel_col_date;

      let studio_col_date = 
      [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '校区名称', width:'120px', dataIndex: 'studio_name',  key: 'studio_name', fixed: 'left',ellipsis:true},
          { title: '合计', width:'60px', dataIndex: 'studio_summary_total', key: 'studio_summary_total',fixed: 'left',align:'right'},
      ]
      if(res_rank.data.channel_follow_table){
          res_rank.data.employee_follow_table.col_date.map(item=>{
              let obj = { title: item, dataIndex: item, key: item,align:'right',width:'60px'}
              studio_col_date.push(obj)
          })
      }

      this.col_studio_follow = res_rank.data.studio_follow_table.rows;
      this.col_studio_follow_columns = studio_col_date;
      

      // let res_contact_type= await this.$store.dispatch('analysisCrmFollowContactTypeAction', obj);
      // this.contact_type_data=res_contact_type.data;

      this.loading = false
    },
    async getFollowChannel(obj){
      this.loadingFC = true
      let res = await this.$store.dispatch('analysisCrmFollowChannelAction', obj)
      this.follow_channel_data = res.data.channel
      this.follow_sub_channel_data = res.data.sub_channel
      this.loadingFC = false
    },
    async getHeatmap(obj){
      this.loadingFH = true
      let res = await this.$store.dispatch('analysisCrmFollowHeatmapAction', obj)
      this.heatmap_data = res.data
      this.loadingFH = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCrmFollowCountAction', obj)
      this.counts = res.data
    },
    async getSourceChannel() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.parentChannels = res.data
    },
    async getStaff(val){
        let obj = {q:val}
        let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
        this.staffList = res.data
    },
    async getEventList(){
        await this.$store.dispatch('cFollowEventAction', {})
        .then(res=>{
            this.eventList = res.data
        })
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen()
    },
    async toExport(){
        this.exportLoading = true
        let exportData = {
            search:this.paramsData
        }
        let res = await this.$store.dispatch('analysisCrmFollowExportAction', exportData)
        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
        if ('download' in document.createElement('a')) { 
            const link = document.createElement('a')
            link.download = `员工线索跟进统计.${moment().format('YYYY.MM.DD')}.xlsx`
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href) 
            document.body.removeChild(link)
        } else { //其他浏览器
            navigator.msSaveBlob(blob, fileName)
        }

        this.exportLoading = false
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj)
    },
    closeSearch() {
      this.visible = false
    },
    reset(){
      this.paramsData.channel_id = undefined
      this.channelsId = []
      this.paramsData.studio_id = undefined
      this.paramsData.created_by = undefined
    },
    handleSearch(val){
        this.getStaff(val)
    },
  }
}
</script>