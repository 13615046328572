<template>
   <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <ve-ring 
                judge-width 
                :data="contact_type_data" 
                :legend="{
                    type:'scroll',
                    orient: 'vertical',
                    left:'0%',
                    align:'left',
                    top:'middle',
                    textStyle: {
                        color:'#8C8C8C'
                    },
                    height:150}" 
                    :extend="{
                    series:{
                        label: {
                            normal: {
                                formatter: params => {
                                    return (
                                        params.name +' '+ params.percent +'%'
                                    );
                                }
                            }
                        }
                    }
        }"></ve-ring>
            </div>
    </a-card>
</template>

<script>
    export default {
        name:"contact_type",
        data () {
            this.toolbox = {
                feature: {
                magicType: {},
                saveAsImage: {pixelRatio:2}
                }
            }
            return{
                
            }
        },
        props:['contact_type_data' , 'loading'],
    }
</script>

<style lang="scss" scoped>

</style>