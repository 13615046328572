var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":""}},[_c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-ring',{attrs:{"judge-width":"","data":_vm.contact_type_data,"legend":{
                 type:'scroll',
                 orient: 'vertical',
                 left:'0%',
                 align:'left',
                 top:'middle',
                 textStyle: {
                     color:'#8C8C8C'
                 },
                 height:150},"extend":{
                 series:{
                     label: {
                         normal: {
                             formatter: function (params) {
                                 return (
                                     params.name +' '+ params.percent +'%'
                                 );
                             }
                         }
                     }
                 }
     }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }